module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.0_gatsby@5.12.5_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.1_isxpjmx2rvmcqlaxmsq2yholp4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"little tokyo","short_name":"little tokyo","start_url":"/","background_color":"#fff","theme_color":"#fff","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c8d0a2b2ca1f6dd0effa5de482fbe5bc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.5_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_react-dom@18.2.0_react@18.2._tvg47wau7oggparz6zn3gswad4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
